import {Pages}       from "./Pages";
import React         from "react";
import ReactDOM      from "react-dom";
import {ProgramForm} from "../components/ProgramForm";

export class ProgramsEdit extends Pages {

    constructor(params) {
        super(params);

        this.data = window.diaryJsData;
        ReactDOM.render(
            <ProgramForm
                formAction={this.data.formAction}
                backUrl={this.data.backUrl}
                departments={this.data.departments}
                programsDurations={this.data.programsDurations}
                element={this.data.element}/>,
            document.getElementById('programForm')
        );

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
    }
}
